import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLiff } from "react-liff";

import Layouts from "layouts/Layouts";
import LoaderPage from "./components/loader/LoaderPage";
import AppRouter from "./appRouter"; // Router


import useProfileStore from "services/store/ProfileModule";
import helper from "services/helper";

// const devMode = true;
const devMode = false;

function App() {
  const { isLoggedIn, isReady, liff } = useLiff()

  // CENTER STATE
  const { profile } = useProfileStore(); // STATE


  // if (!isReady && !isDone) {
  //   return <LoaderPage />
  // }
  const isHasProfile = helper.Auth.isLogin()
  return (
    <Layouts>
      <AppRouter isHasProfile={isHasProfile}/>
    </Layouts>
  );
}

export default App;
