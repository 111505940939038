import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import useProfileStore from "services/store/ProfileModule";
import TopRestaurant from "components/TopRestaurant";
import BottomRestaurant from "components/BottomRestaurant";
import PopupCollectPoint from "./collectPoint/PopupCollectPoint";
import PopupCoupon from "./coupon/PopupCoupon";

const Profile = () => {
  const navigate = useNavigate();
  
  // CENTER STATE
  const { profile, getCustomerProfile } = useProfileStore(); // STATE  
  const [ isOpenCollectPoint, setIsOpenCollectPoint ] = useState(false);
  const [ isOpenCoupon, setIsOpenCoupon ] = useState(false);

  useEffect(() => {}, []);

  return (
    <div>
      <Helmet>
        <title></title>
        <meta name="title" content="" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>
      <div id="pageProfile-restaurant">
      
      {/* <!-- Content Page --> */}
      <main id="top">
        <div className="wrapContent">

          {/* <!-- Page Top Card --> */}
          {/* <?php include 'inc/inc-top-restaurant.php';?> */}
          <TopRestaurant/>
          
          <section id="pFuction">
            <div className="bFunction">
              <div className="bBtn">
                <a onClick={()=> setIsOpenCollectPoint(true)}>
                  <i className="ico-qr"></i>
                  <span>สะสมคะแนน</span>
                </a>
                <a onClick={()=> setIsOpenCoupon(true)}>
                  <i className="ico-coupon"></i>
                  <span>ใช้สิทธิ์แลกคูปอง</span>
                </a>
              </div>
            </div>
          </section>

          {/* PopupCollectPoint */}
          <PopupCollectPoint open={isOpenCollectPoint} onClose={() => setIsOpenCollectPoint(false)}/>

          {/* PopupCoupon */}
          <PopupCoupon open={isOpenCoupon} onClose={() => setIsOpenCoupon(false)}/>


          {/* <!-- Page Button Menu --> */}
          {/* <?php include 'inc/inc-bottom-restaurant.php';?> */}
          <BottomRestaurant/>

        </div>
      </main>

    </div>
    </div>
  );
};

export default Profile;
