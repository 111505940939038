import Login from "pages/Login";
import LoginComplete from "pages/LoginComplete";
import NotFound from "pages/NotFound";
import Profile from "pages/Profile";
import CollectPointConfirm from "pages/collectPoint/CollectPointConfirm";
import CollectPointQR from "pages/collectPoint/CollectPointQR";
import CollectPointTel from "pages/collectPoint/CollectPointTel";
import CouponCode from "pages/coupon/CouponCode";
import CouponConfirm from "pages/coupon/CouponConfirm";
import CouponQR from "pages/coupon/CouponQR";
import Example from "pages/example/Example";
import { BrowserRouter as Router,  Route } from "react-router-dom";
import { Routes, Navigate } from "react-router-dom";
import helper from "services/helper";

const appRouter = ({ isHasProfile = false }) => {
  // console.log("🚀 ~ appRouter ~ isHasProfile:", isHasProfile)
  
  return (
    <Router>
      <Routes>
        <Route path="/example" element={<Example/>} />
        <Route path="/" element={!isHasProfile ? <Login /> : <Navigate to="/profile" /> } />
        <Route path="/login" element={!isHasProfile ? <Login /> : <Navigate to="/profile" /> } />
        <Route path="/login-complete" element={<LoginComplete />} />
        <Route path="/profile" element={isHasProfile ? <Profile /> : <Navigate to="/login" />} />
        
        <Route path="/collect-point-tel" element={isHasProfile ? <CollectPointTel /> : <Navigate to="/login" />} />
        <Route path="/collect-point-qr" element={isHasProfile ? <CollectPointQR /> : <Navigate to="/login" />} />
        <Route path="/collect-point-confirm" element={isHasProfile ? <CollectPointConfirm /> : <Navigate to="/login" />} />


        <Route path="/coupon-code" element={isHasProfile ? <CouponCode /> : <Navigate to="/login" />} />
        <Route path="/coupon-qr" element={isHasProfile ? <CouponQR /> : <Navigate to="/login" />} />
        <Route path="/coupon-confirm" element={isHasProfile ? <CouponConfirm /> : <Navigate to="/login" />} />
        <Route path="*" element={<NotFound/>} />

      </Routes>
    </Router>
  );
};

export default appRouter;