import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { GetCustomerProfile, authLogin } from "services/Api/Module/Auth";
import helper from "services/helper";
const LoginComplete = () => {
  const navigate = useNavigate();
  useEffect(() => {}, []);

  return (
    <div>
      <Helmet>
        <title></title>
        <meta name="title" content="" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>
      <div id="pageRestaurant-complete">
        <main id="top">
          <div className="wrapContent">
            <section id="pComplete">

              <div className="bComplete">
                <div className="bText">
                  <img src="assets/img/ci/complete/ico-complete.svg" alt=""/>
                  <h1>เข้าสู่ระบบร้านค้า!</h1>
                </div>
                <div className="bBtn">
                  <a onClick={() => window.location.href = "/profile" } className="btn white">เริ่มต้น</a>
                </div>
              </div>

            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default LoginComplete;
