// import { GetProfileView } from 'services/Api/Module/Profile';
import { persist } from 'zustand/middleware'
import { create } from 'zustand';
import { GetProfile } from 'services/Api/Module/Auth';

const defaultLineUid = '$lineUid'
const defaultProfile = { isLoad: false, profile: {} }

const useProfileStore = create(
      (set) => ({
        isLoaded: false,
        profile: defaultProfile,
        customerProfile: {},

        setProfile: (payload = { lineUid: '', profile: {} })=> {
          set({ isLoad: true });
          set({ lineUid: payload.lineUid, profile: payload.profile });
        },

        getCustomerProfile: async () => {
          console.log("🚀 ~ getCustomerProfile: ~ getCustomerProfile!!!")
          try {
            const response = await GetProfile({ params: ""});
            const resData = response.data
            console.log("🚀 ~ getCustomerProfile: ~ resData:", resData)
            set({ profile: resData.resultData });
            return resData;
          } catch (error) {
            console.error('Error adding customer:', error);
            // return error;
            throw error;
          }
        },
    
        resetProfile: (payload = { lineUid: '', profile: {} })=> {
          set({ isLoad: true });
          set({ profile: {} });
        }
      }
    )
);

export default useProfileStore;