// src/QrScanner.js
import React, { useState } from 'react';
import QrScanner from 'react-qr-scanner';
import QrScanNew from './QrScanNew';

const QrCodeScanner = ({
  format = '',
  onDetect = () => { console.log('onDetect') }
}) => {
  const handleScan = (text) => {
    if (text) {
      console.log(text)
      if (format) {
        if (`${text}`.startsWith(format)) {
          onDetect(text)
        }
      } else {
          onDetect(text)
      }
      // setResult(data);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };
  const previewStyle = {
    height: 320,
    width: 320,
  };

  return (
    <QrScanNew onScan={handleScan}/>
  )
  // return (
  //   <>
  //     <QrScanner
  //       delay={300} // หน่วยเป็นมิลลิวินาท
  //       className="cam"
  //       onError={handleError}
  //       onScan={handleScan}
  //       constraints={{
  //         audio: false,
  //         video: { facingMode: "environment" }} 
  //       }
  //     />
  //   </>
  // );
};

export default QrCodeScanner;
