import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ModalContext from "contexts/ModalContext";
import helper from "services/helper";
import PageHeader from "components/PageHeader";
import { SearchCouponRedeem } from "services/Api/Module/CouponRedeem";
import BottomAction from "components/BottomAction";

const CouponCode = () => {
  const navigate = useNavigate();
  const { mAlert } = useContext(ModalContext);

  const [ couponCode, setCouponCode ] = useState("")

  // SearchCouponRedeem
  const fetchSearchCouponRedeem = async (payload) => {
    try {
      const response = await SearchCouponRedeem({ params: payload })
      const resData = response.data
      if (resData.resultCode === 20000) {
        navigate(`/coupon-confirm?couponCode=${couponCode}`)
      } 
      else if (resData.resultCode === 40901) {
        mAlert.info({
          title: "คูปองนี้หมดอายุแล้ว",
          content: "",
          onClose: () => {},
        })
      }
      else {
        mAlert.info({
          title: "ไม่พบข้อมูลคูปอง",
          content: "",
          onClose: () => {},
        })
      }
    }
    catch (err) {
      console.log('err ', err)
      mAlert.info({
        title: "แจ้งเตือน",
        subTitle: "มีบางอย่างผิดพลาด",
        content: "",
        onClose: () => {},
      })
    }
  }




  const onNext = () => {
    // alert("onNext!")
    const payload = {
      couponCode: couponCode
    }
    console.log("🚀 ~ onNext ~ payload:", payload)
    fetchSearchCouponRedeem(payload)
    
  }
  useEffect(() => {}, []);

  return (
    <div>
      <Helmet>
        <title></title>
        <meta name="title" content="" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>
      <div id="pageVerified">

        {/* <!-- Page Header --> */}
        <PageHeader title="ใช้รหัสคูปอง" onBack={() => navigate("/profile")}/>

        {/* <!-- Content Page --> */}
        <main id="top">
          <div className="wrapContent">
            <section id="pRegister">
              <div className="bRegisterMain">
                <form >
                  <div className="bForm">
                    <h2>ใช้รหัสคูปอง</h2>
                    <div className="bGroup">
                      <div className="bRow">
                        <div className="control-group">
                          <div className="inputPlaceholder">
                            <span>IMP-XXXXXXXXXXXXXXXXX</span>
                            <input 
                              type="text" 
                              inputmode="numeric" 
                              value={couponCode} 
                              onInput={helper.FNFORM.handleCharOnly}
                              onChange={(e) => setCouponCode(e.target.value)} 
                              maxLength={50}
                              name="" 
                              placeholder="IMP-XXXXXXXXXXXXXXXXX"
                            />
                          </div>
                          <label for="" className="tError">กรุณากรอกเบอร์โทรศัพท์</label>
                        </div>
                      </div>
                    </div>
                  </div>                
                </form>
              </div>
            </section>

            <BottomAction type="next" onNext={onNext} />
          </div>
        </main>

      </div>
     
    </div>
  );
};

export default CouponCode;
