import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import useProfileStore from "services/store/ProfileModule";
import helper from "services/helper";

const BottomAction = ({
  type = "info",
  onNext = () => console.log('onNext'),
  onYes = () => console.log('onYes'),
  onNo = () => console.log('onNo')
}) => {
  const navigate = useNavigate();
  // CENTER STATE
  const { profile } = useProfileStore(); // STATE

  useEffect(() => {}, []);

  return (
    <section id="pBottomAction">
      <div className="bBottomAction">
          <div className="bBtn">
            {
              type === "next"
              ? (
                <button className="btn" onClick={() => onNext()}>ต่อไป</button>
              )
              : (
                <>
                  <button className="btn outline" onClick={() => onNo()}>ยกเลิก</button>
                  <button className="btn" onClick={() => onYes()}>บันทึก</button>
                </>
              )
            }
            
          </div>
        </div>
      </section>

  );
};

export default BottomAction;
