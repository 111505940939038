import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import useProfileStore from "services/store/ProfileModule";
import helper from "services/helper";

const BottomRestaurant = () => {
  const navigate = useNavigate();
  // CENTER STATE
  const { profile } = useProfileStore(); // STATE

  useEffect(() => {}, []);

  return (
    <section id="pBottomMenu">
      <div className="bBottomMenu">
        <nav>
          <a onClick={() => navigate("/profile")} className="menuProfile active"><i></i><span>โปรไฟล์</span></a>
        </nav>
      </div>
    </section>
  );
};

export default BottomRestaurant;
