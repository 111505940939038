import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { LiffProvider } from "react-liff";
import TagManager from "react-gtm-module";

import App from "./App";
import store from "./services/Store";
import config from "util/config";
import { PrimeReactProvider, PrimeReactContext } from "primereact/api";
import "./index.css";

const CONFIG = config.config();

let initialized = false;

const fnHandleLiff = (liff) => {
  
  // เช็ค liff เปิดจาก app only (prod)
  if (CONFIG.APP_ENV === "prod") {
    if (!liff.isInClient()) {
      window.location = "/error";
    }
  }
  
  if (initialized === false) {
    initialized = true;

    if (typeof liff.error !== "undefined") {
      console.warn("error", liff.error);
    }

    liff.ready.then(() => {
      console.log("ready :", liff.isLoggedIn());

      // PROFILE TO LOCAL STORAGE
      liff
        .getProfile()
        .then((profile) => {
          console.log("profile :", profile);
        })
        .catch((err) => {
          console.log("error :", err);
        });
    });
  }
};

// CONFIG LIFF 
const liffId = CONFIG.LIFF_ID;
let isLoginBrowser = true;

// ---- GTM START ----
const tagManagerArgs = { gtmId: "" }; // ใส่ GTM ตรงนี้
TagManager.initialize(tagManagerArgs);
// ---- GTM END ----

// For Check Search Path
const search = window.location.search;
const path = window.location.pathname;

// Default REACT
const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  <PrimeReactProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </PrimeReactProvider>
);