import React, { useState, useEffect } from "react";
import DialogCustom from "./DialogCustom";

function ModalAlertConfrim({ 
  open = false,
  title = '',
  subTitle = '',
  content = `มีบางอย่างผิดพลาด`,
  mode = "blue",
  onClose = () => console.log('onClose'),
  onYes = () => console.log('onYes'),
  onNo = () => console.log('onNo')
}) {
  let labelCancel = 'ยกเลิก'
  let colorMode = `blue`
  if (mode === "red") {
    colorMode = mode
    labelCancel = 'ไม่ต้องการยกเลิก'
  }
  return (
    <DialogCustom visible={open} style={{ width: '342px' }} onHide={() => onClose()} onClickOut = {() => onClose()} draggable={false} resizable={false} closable={false}>
      <div class={`bModal bCard ${colorMode}`} id="ModalAlertConfrim">
        <div className="bInner" style={{ width: "100%"}}>
          <div className="bDetails">
            {/* <!-- <i className="icoAlert"></i> --> */}
            { title ? <h3 dangerouslySetInnerHTML={{ __html: title }}></h3> : <></> }
            { subTitle ? <p dangerouslySetInnerHTML={{ __html: subTitle }}></p> : <></> }
            { content ? <p dangerouslySetInnerHTML={{ __html: content }}></p> : <></> }
          </div>
          <div className="bBtn">
            <a onClick={() => onYes()} className="btn">ยืนยัน</a>
            <a onClick={() => onNo()} className="btn outline">{labelCancel}</a>
          </div>
        </div>
      </div>
    </DialogCustom>
  )
}

export default ModalAlertConfrim;
