import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ModalContext from "contexts/ModalContext";
import helper from "services/helper";
import PageHeader from "components/PageHeader";
import { SearchCustomer } from "services/Api/Module/Customer";
import BottomAction from "components/BottomAction";
import QrCodeScanner from "components/QrCodeScanner";

const CollectPointQR = () => {
  const navigate = useNavigate();
  const { mAlert } = useContext(ModalContext);

  const [refresh, setRefresh] = useState(false);
  const [custCode, setCustCode] = useState("");
  // SearchCustomer
  const fetchSearchCustomer = async (payload) => {
    try {
      const response = await SearchCustomer({ params: payload });
      const resData = response.data;
      if (resData.resultCode === 20000) {
        setRefresh(true);
        setTimeout(() => {
          navigate(`/collect-point-confirm?custCode=${custCode}`);
          setRefresh(false);
        }, 400);
      } else {
        mAlert.info({
          title: "ไม่พบเบอร์มือถือ",
          subTitle: "เบอร์มือถือนี้ไม่ตรงกับเบอร์ในระบบ",
          content: "",
          onClose: () => {
            setRefresh(true);
            setTimeout(() => setRefresh(false), 400);
          },
        });
      }
    } catch (err) {
      console.log("err ", err);
      mAlert.info({
        title: "แจ้งเตือน",
        subTitle: "มีบางอย่างผิดพลาด",
        content: "",
        onClose: () => {},
      });
    }
  };

  const onNext = () => {
    // alert("onNext!")
    const payload = {
      custCode: custCode,
    };
    console.log("🚀 ~ onNext ~ payload:", payload);
    fetchSearchCustomer(payload);
  };

  const onDetect = (value) => {
    if (value !== custCode) {
      console.log("🚀 ~ onDetect ~ value:", value);
      setCustCode(value);
      setTimeout(() => {
        setCustCode("");
      }, 2000);
    }
  };

  useEffect(() => {
    if (custCode) {
      onNext();
    }
  }, [custCode]);

  useEffect(() => {}, []);

  return (
    <div>
      <Helmet>
        <title></title>
        <meta name="title" content="" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pageRestaurant-scanqr">
        {/* <!-- Content Page --> */}
        <main id="top">
          <div className="wrapContent">
            <a
              className="btnBack"
              style={{
                fontSize: "16px",
                color: "#FFF",
                fontWeight: "700",
              }}
              onClick={() => navigate("/profile")}
            >
              กลับ
            </a>
            <section id="pCamera">
              <div className="bCamera">
                <div className="bText">
                  <h1>วาง QR ให้อยู่ในพื้นที่ที่กำหนด</h1>
                </div>
                <div className="bDevice">
                  <div className="bInputDevice">
                    {!refresh ? (
                      <QrCodeScanner onDetect={onDetect} format="IMP" />
                    ) : (
                      <div>....</div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default CollectPointQR;
