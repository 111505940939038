import React, { useState, useEffect, useRef } from "react";
import { Dialog } from 'primereact/dialog';

function DialogCustom({
  visible = false,
  style = { width: '342px' },
  onHide = () => console.log('onHide'),
  onClickOut = () => console.log('onClickOut'), 
  children
}) {

  // Handle click outside
  const handleClickOutside = (event) => {
    if (event.target.classList.contains('p-dialog-mask')) {
      onClickOut()
    }
  };
  
  useEffect(() => {
    if (visible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [visible]);

  return (
    <>
      {/* { open ? <div className="bOutPopup" onClick={() => alert("OUT")}></div> : <></> } */}
      <Dialog visible={visible} style={style} onHide={() => onHide()} onClickOut={() => onClickOut()} draggable={false} resizable={false} closable={false}>
        {children}
      </Dialog>
    </>
  )
}

export default DialogCustom;
