import React, { useState } from "react";
import MyContext from "./ModalContext";
import ModalAlert from "components/modal/ModalAlert";
import ModalAlertConfrim from "components/modal/ModalAlertConfrim";

const defaultInfo = {
  open: false,
  type: "info",
  title: "$title",
  subTitle: "$subTitle",
  mode: "blue",
  content: "",
  onClose: () => {},
  onYes: () => {},
  onNo: () => {},
};
const ModalProvider = ({ children }) => {
  const [infoShow, setInfoShow] = useState(false);
  const [info, setInfo] = useState(defaultInfo);

  const onAlertInfo = (newData) => {
    const { open = true } = newData
    setInfoShow(open);
    if (newData.open) {
      setInfo(newData);
    } else {
      setTimeout(() => setInfo(newData), 10);
    }
  };

  const ModalComponent = () => {
    return (
      <>
        {
          info.type === "confrim"
          ? (
            <ModalAlertConfrim
              open={infoShow}
              title={info.title}
              subTitle={info.subTitle}
              content={info.content}
              mode={info.mode}

              onClose={() => {
                onAlertInfo(defaultInfo);
                info.onClose();
              }}

              onNo={() => {
                onAlertInfo(defaultInfo);
                info.onNo();
              }}

              onYes={() => {
                onAlertInfo(defaultInfo);
                info.onYes();
              }}
            />
          )
          : (
            <ModalAlert
              open={infoShow}
              title={info.title}
              subTitle={info.subTitle}
              content={info.content}
              onClose={() => {
                onAlertInfo(defaultInfo);
                info.onClose();
              }}
            />
          )
        }
      </>
    )
  }

  return (
    <MyContext.Provider value={{ mAlert: { info: onAlertInfo } }}>
      { info.title !== "$title" ? ModalComponent() : <></>}
      {children}
    </MyContext.Provider>
  );
};

export default ModalProvider;
