import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import ModalContext from "contexts/ModalContext";
import helper from "services/helper";
import PageHeader from "components/PageHeader";
import { SearchCouponRedeem } from "services/Api/Module/CouponRedeem";
import useProfileStore from "services/store/ProfileModule";
import { UpdateCouponRedeem } from "services/Api/Module/CouponRedeem";
import BottomAction from "components/BottomAction";

const defPointCondition = { bathPerPoint: 25, point: 1 }

const CouponConfirm = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const couponCode = searchParams.get("couponCode");

  // POPUP
  const { mAlert } = useContext(ModalContext);

  // CENTER STATE
  const { profile } = useProfileStore(); // STATE  

  // LOCAL STATE
  const [ isSubmit, setIsSubmit ] = useState(false)
  const [ couponRedeem, setCouponRedeem ] = useState({})
  const [ reference, setReference ] = useState("")
  const [ cash, setCash ] = useState("")
  const [ pointCondition, setPointCondition ] = useState(defPointCondition)

  // SearchCouponRedeem
  const fetchSearchCouponRedeem = async (payload) => {
    try {
      const response = await SearchCouponRedeem({ params: payload })
      const resData = response.data
      if (resData.resultCode === 20000) {
        navigate(`/coupon-confirm?couponCode=${couponCode}`)
        setCouponRedeem(resData.resultData)
      } 
      else if (resData.resultCode === 40901) {
        mAlert.info({
          title: "คูปองนี้หมดอายุแล้ว",
          content: "",
          onClose: () => {},
        })
      }
      else {
        mAlert.info({
          title: "ไม่พบข้อมูลคูปอง",
          content: "",
          onClose: () => {},
        })
      }
    }
    catch (err) {
      console.log('err ', err)
      mAlert.info({
        title: "แจ้งเตือน",
        subTitle: "มีบางอย่างผิดพลาด",
        content: "",
        onClose: () => {},
      })
    }
  }

  const putCouponRedeem = async (payload) => {
    try {
      const response = await UpdateCouponRedeem({ data: payload })
      const resData = response.data
      console.log("🚀 ~ putCouponRedeem ~ resData:", resData)
      if (resData.resultCode === 20000) {
        // setCustomer(resData.resultData)
        mAlert.info({
          title: "ใช้คูปองสำเร็จ",
          subTitle: "",
          content: "",
          onClose: () => { navigate("/profile")},
        })
      } 
      else if (resData.resultCode === 40901) {
        mAlert.info({
          title: "คูปองนี้หมดอายุแล้ว",
          content: "",
          onClose: () => {},
        })
      } else {
        mAlert.info({
          title: "ไม่พบข้อมูล",
          subTitle: "ข้อมูลนี้ไม่ตรงกับข้อมูลในระบบ",
          content: "",
          onClose: () => {},
        })
      }
    }
    catch (err) {
      console.log('err ', err)
      mAlert.info({
        title: "แจ้งเตือน",
        subTitle: "มีบางอย่างผิดพลาด",
        content: "",
        onClose: () => {},
      })
    }
  }

  

  const onSave = () => {
    // alert("onSave!")
    setIsSubmit(true)

    const payload = {
      // cash: cash,
      customerId: couponRedeem.customer,
      couponCode: couponCode,
      reference: reference
    }
    console.log("🚀 ~ onSave ~ payload:", payload)

  
    mAlert.info({
      title: `คุณยืนยันจะใช้คูปอง?`,
      subTitle: "",
      type: "confrim",
      content: "",
      onClose: () => {},
      onYes: () => {
        putCouponRedeem(payload)
      },
      onNo: () => { console.log('SAY NO') },
    })
    
  }

  const onCancel = (e) => {
    // alert("onCancel")

    mAlert.info({
      title: "ยกเลิกการบันทึกคูปอง?",
      subTitle: "หากกดยกเลิก ข้อมูลที่ทำการบันทึก <br/> คูปองจะหายไปทันที",
      type: "confrim",
      mode: "red",
      content: "",
      onClose: () => {},
      onYes: () => { navigate("/profile")},
      onNo: () => { console.log('SAY NO') },
    })
  }

  useEffect(() => {
    const payload = { couponCode: couponCode }
    fetchSearchCouponRedeem(payload)
  }, [couponCode]);
  
  useEffect(() => {
    if(profile.id) {
      const tmp = {
        bathPerPoint: profile.pointConditionBathPerPoint,
        point: profile.pointConditionPoint
      }
      setPointCondition(tmp)
    }
  }, [profile]);
  
  
  console.log('profile >>', profile)
  console.log("🚀 ~ CollectPointConfirm ~ reference:", reference)
  console.log("🚀 ~ CollectPointConfirm ~ cash:", cash)

  // VALIDATE
  const ckInput = (condition = false) => (isSubmit && !condition) ? 'error' : ''

  const _pointCondition = pointCondition
  const calPoint = Math.floor(cash / _pointCondition.bathPerPoint) * _pointCondition.point

  return (
    <div>
      
      <Helmet>
        <title></title>
        <meta name="title" content="" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pageRestaurant-coupon-details">

        {/* <!-- Page Header --> */}
        <PageHeader title="ยืนยันการใช้คูปอง" onBack={() => navigate("/profile")}/>

        {/* <!-- Content Page --> */}
        <main id="top">
          <div className="wrapContent">
            <section id="pRegister">
              <div className="bRegisterMain">
                <div className="bForm">
                  <div className="bGroup">
                    <h2>รายละเอียด</h2>
                    <div className="bRowInfo">
                      <h4>รหัสคูปอง</h4>
                      <p>{couponRedeem?.couponCode ?? '-'}</p>
                    </div>
                    <div className="bRowInfo">
                      <h4>ชื่อคูปอง</h4>
                      <p>{couponRedeem?.couponName ?? '-'}</p>
                    </div>
                    <div className="bRowInfo">
                      <h4>วันที่ใช้คูปอง</h4>
                      {/* <p>{couponRedeem?.createdOn ?? '-'}</p> */}
                      <p>{couponRedeem?.createdOn ? moment(couponRedeem?.createdOn).format('DD/MM/YYYY HH:mm') : '-'}</p>
                    </div>
                    <div className="bRowInfo">
                      <h4>วันหมดอายุคูปอง</h4>
                      <p>{couponRedeem?.expiredAt ? moment(couponRedeem?.expiredAt).format('DD/MM/YYYY HH:mm') : '-'}</p>
                    </div>
                    <div className="bRowInfo">
                      <div>
                        <h3>หมายเลขรายการ</h3>
                        <div className="control-group">
                          <input 
                            type="text" 
                            value={reference}
                            onInput={helper.FNFORM.handleCharOnly}
                            onChange={(e) => setReference(e.target.value)} 
                            maxLength={100}
                            name="" 
                            placeholder="หมายเลขรายการ"
                          />
                          <label for="" className="tError">Text Error Input</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>                
              </div>
            </section>

            {/* <!-- Page Button Menu --> */}
            <BottomAction onYes={onSave} onNo={onCancel} />


          </div>
        </main>

      </div>
    </div>
  );
};
export default CouponConfirm;