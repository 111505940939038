import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ModalContext from "contexts/ModalContext";
import helper from "services/helper";
import PageHeader from "components/PageHeader";
import { SearchCouponRedeem } from "services/Api/Module/CouponRedeem";
import BottomAction from "components/BottomAction";
import QrCodeScanner from "components/QrCodeScanner";

const CouponQR = () => {
  const navigate = useNavigate();
  const { mAlert } = useContext(ModalContext);

  const [refresh, setRefresh] = useState(false);
  const [couponCode, setCouponCode] = useState("");

  // SearchCouponRedeem
  const fetchSearchCouponRedeem = async (payload) => {
    try {
      const response = await SearchCouponRedeem({ params: payload });
      const resData = response.data;
      if (resData.resultCode === 20000) {
        setRefresh(true);
        setTimeout(() => {
          navigate(`/coupon-confirm?couponCode=${couponCode}`);
          setRefresh(false);
        }, 400);
      } else if (resData.resultCode === 40901) {
        mAlert.info({
          title: "คูปองนี้หมดอายุแล้ว",
          content: "",
          onClose: () => {},
        });
      } else {
        mAlert.info({
          title: "ไม่พบข้อมูลคูปอง",
          content: "",
          onClose: () => {},
        });
      }
    } catch (err) {
      console.log("err ", err);
      mAlert.info({
        title: "แจ้งเตือน",
        subTitle: "มีบางอย่างผิดพลาด",
        content: "",
        onClose: () => {},
      });
    }
  };

  const onNext = () => {
    // alert("onNext!")
    const payload = {
      couponCode: couponCode,
    };
    console.log("🚀 ~ onNext ~ payload:", payload);
    fetchSearchCouponRedeem(payload);
  };

  const onDetect = (value) => {
    if (value !== couponCode) {
      console.log("🚀 ~ onDetect ~ value:", value);
      setCouponCode(value);
      setTimeout(() => {
        setCouponCode("");
      }, 2000);
    }
  };

  useEffect(() => {
    if (couponCode) {
      onNext();
    }
  }, [couponCode]);

  useEffect(() => {}, []);

  return (
    <div>
      <Helmet>
        <title></title>
        <meta name="title" content="" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pageRestaurant-scanqr">
        {/* <!-- Content Page --> */}
        <main id="top">
          <div className="wrapContent">
            <a
              className="btnBack"
              style={{
                fontSize: "16px",
                color: "#FFF",
                fontWeight: "700",
              }}
              onClick={() => navigate("/profile")}
            >
              กลับ
            </a>
            <section id="pCamera">
              <div className="bCamera">
                <div className="bText">
                  <h1>วาง QR ให้อยู่ในพื้นที่ที่กำหนด</h1>
                </div>
                <div className="bDevice">
                  <div className="bInputDevice">
                    {!refresh ? (
                      <QrCodeScanner onDetect={onDetect} />
                    ) : (
                      <div>....</div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default CouponQR;
