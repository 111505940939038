import { get, post, put } from "../Config";


export function SearchCouponRedeem({ params = {} }) {
  return get({ url: `/api/impact-admin/v1/searchCouponRedeem`, params });
}

export function CreateCouponRedeem({ data = {} }) {
  return post({ url: `/api/impact-admin/v1/redeemCoupon`, data });
}
export function UpdateCouponRedeem({ data = {} }) {
  return post({ url: `/api/impact-admin/v1/useCouponRedeem`, data });
}
