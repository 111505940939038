import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import useProfileStore from "services/store/ProfileModule";
import helper from "services/helper";

import ModalContext from "contexts/ModalContext";
import $ from "jquery";
import { authLogout } from "services/Api/Module/Auth";

const TopRestaurant = () => {
  const navigate = useNavigate();
  
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // CENTER STATE
  const { profile } = useProfileStore(); // STATE

  const postLogout = async (payload) => {
    try { 
      const response = await authLogout({ data: payload })
      const resData = response.data
      console.log("🚀 ~ authLogin ~ resData:", resData)
      // const resData = await getCustomerProfile()
      if (resData.resultCode === 20000) {
        helper.Auth.Logout(() => {
          window.location.href = '/login'
          // mAlert.info({
          //   title: "ออกจากระบบสำเร็จ",
          //   subTitle: "",
          //   content: "",
          //   onClose: () => {
          //     window.location.href = '/login'
          //   },
          // })
        })
      } else {
        mAlert.info({
          title: "แจ้งเตือน",
          subTitle: "มีบางอย่างผิดพลาด",
          content: "",
          onClose: () => {},
        })
      }
    }
    catch (err) {
      console.log('err ', err)
      mAlert.info({
        title: "แจ้งเตือน",
        subTitle: "มีบางอย่างผิดพลาด",
        content: "",
        onClose: () => {},
      })
    }
  }
  
  const onLogOut = () => {
    postLogout()
  }

  

  useEffect(() => {
    /* ------------------------------------- Menu Mobile ------------------------------------- */
    $("#btnMenu,#btnCloseMenu,#menuDrawer a[href]").click(function (e) { 
      $('body').toggleClass('openedMenu');
      $('#menuDrawer').toggleClass('opened');
      $('header').toggleClass('opened');
      $('.bHideArea').fadeToggle();
      // $(this).toggleClass('opened')
      $('header').removeClass('active')
    });
    $("#menuDrawer a[href]").click(function (e) { 
      $('body').removeClass('openedMenu');
    });

    return () => {
      $("#btnMenu,#btnCloseMenu,#menuDrawer a[href]").off("click");
    };
  }, []);


  return (
    <>
      <section id="pTopcard-restaurant">
        <div className="bTopcard">
          <div className="bTopRestaurant">
            <div className="bLogo">
              <img src={profile.restaurantImg} alt=""/>
              {/* <img src="assets/img/logo/logo-color.svg" alt=""/> */}
            </div>
            <div className="bName">
              <h1>{profile?.restaurantName ?? '-'}</h1>
              <span>สาขา {profile?.restaurantBranchName ?? '-'}</span>
            </div>
          </div>
          <a id="btnMenu"><span></span></a>
        </div>
      </section>

      <div className="bHideArea"></div>

      <aside id="menuDrawer">
        <a id="btnCloseMenu"></a>
        <div className="bInner">
          <div className="bLogo">
            <div className="bLogo">
              {/* <img src={profile.restaurantImg} alt=""/> */}
              <img src="assets/img/logo/logo-white.svg" alt=""/>
            </div>
          </div>
          <nav>
            <a style={{ cursor: "pointer" }} onClick={onLogOut} className="logout"><i className="ico-logout"></i>ออกจากระบบ</a>
          </nav>
        </div>
      </aside>
    </>
  )
};

export default TopRestaurant;
