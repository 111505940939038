import React, { useState, useEffect, useRef } from 'react';
import jsQR from 'jsqr';
import '../index.css';

function QrScanNew({
  onScan = () => { console.log('onScan') },
  onError = () => { console.log('onError') }
}) {
  const [loadingMessage, setLoadingMessage] = useState('🎥 Unable to access video stream (please make sure you have a webcam enabled)');
  const [outputMessage, setOutputMessage] = useState('No QR code detected.');
  const [outputData, setOutputData] = useState('');
  const [capturedImage, setCapturedImage] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [waiting, setWaiting] = useState(null);

  useEffect(() => {
    const video = videoRef.current;
    const canvasElement = canvasRef.current;
    const canvas = canvasElement.getContext('2d');
    let animationFrameId;

    const tick = () => {
      setLoadingMessage('⌛ Loading video...');
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        setLoadingMessage('');
        canvasElement.hidden = false;

        canvasElement.height = video.videoHeight;
        canvasElement.width = video.videoWidth;
        canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);

        const imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: 'dontInvert',
        });

        if (code) {
          drawLine(canvas, code.location.topLeftCorner, code.location.topRightCorner, '#FF3B58');
          drawLine(canvas, code.location.topRightCorner, code.location.bottomRightCorner, '#FF3B58');
          drawLine(canvas, code.location.bottomRightCorner, code.location.bottomLeftCorner, '#FF3B58');
          drawLine(canvas, code.location.bottomLeftCorner, code.location.topLeftCorner, '#FF3B58');
          setOutputMessage('');
          setOutputData(code.data);
          onScan(code.data)

          if (code.data !== '' && !waiting) {
            video.pause();

            // Capture the image
            const capturedCanvas = document.createElement('canvas');
            capturedCanvas.width = canvasElement.width;
            capturedCanvas.height = canvasElement.height;
            const capturedContext = capturedCanvas.getContext('2d');
            capturedContext.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
            // setCapturedImage(capturedCanvas.toDataURL('image/png'));

            setTimeout(() => {
              video.play();
            }, 4500);

            setWaiting(setTimeout(() => {
              setWaiting(null);
            }, 5000));
          }
        } else {
          setOutputMessage('No QR code detected.');
          setOutputData('');
        }
      }

      animationFrameId = requestAnimationFrame(tick);
    };

    navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
      .then((stream) => {
        if (video) {
          video.srcObject = stream;
          video.setAttribute('playsinline', true); // required to tell iOS safari we don't want fullscreen
          video.play();
          requestAnimationFrame(tick);
        }
      });

    return () => {
      cancelAnimationFrame(animationFrameId);
      if (video && video.srcObject) {
        video.srcObject.getTracks().forEach(track => track.stop());
      }
    };
  }, [waiting]);

  const drawLine = (canvas, begin, end, color) => {
    canvas.beginPath();
    canvas.moveTo(begin.x, begin.y);
    canvas.lineTo(end.x, end.y);
    canvas.lineWidth = 4;
    canvas.strokeStyle = color;
    canvas.stroke();
  };

  return (
    <div className="wrap-qrcode-scanner">
        {/* <div id="loadingMessage">{loadingMessage}</div> */}
        <canvas id="canvas" ref={canvasRef} hidden></canvas>
          <div id="output">
            {/* <div id="outputMessage">{outputMessage}</div>
              {
                outputData && (
                  <div><b>Data:</b> <span id="outputData">{outputData}</span></div>
                )
              } 
             */}
            {capturedImage && (
              <div>
                <b>Captured Image:</b>
                <br />
                {/* <img src={capturedImage} alt="Captured QR Code" style={{ width: '100%' }} /> */}
              </div>
            )}
          </div>
          <video ref={videoRef} style={{ display: 'none' }}></video>
          {/* <img id="outputqrcode" alt="QR Code" /> */}
        <canvas id="canvas2"></canvas>
    </div>
  );
}

export default QrScanNew;
