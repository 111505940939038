import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DialogCustom from "components/modal/DialogCustom";

function PopupCoupon({ 
  open = false, 
  onClose = () => console.log('onClose')
}) {
  const navigate = useNavigate()
  return (
    <DialogCustom visible={open} style={{ width: '342px' }} onHide={() => onClose()} onClickOut = {() => onClose()} draggable={false} resizable={false} closable={false}>
      <div className="bModal bCard" id="ModalAlert">
        <div className="bInner" style={{ width: "100%"}}>
          <div className="bDetails">
            {/* <!-- <i className="icoAlert"></i> --> */}
            <h3>วิธีการใช้คูปอง</h3>
          </div>
          <div className="bBtn" style={{ display: 'flex', flexDirection: "column", gap: "16px" }}>
            <a className="btn" onClick={() => navigate("/coupon-qr")}>สแกน QR</a>
            <a className="btn" onClick={() => navigate("/coupon-code")}>ใช้รหัสคูปอง</a>
          </div>
        </div>
      </div>
    </DialogCustom>
  );
}

export default PopupCoupon;
