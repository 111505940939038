import React, { useState, useEffect, useRef } from "react";
import helper from "services/helper";
import DialogCustom from "./DialogCustom";

function ModalAlert({ 
  open = false,
  title = '',
  subTitle = '',
  content = `มีบางอย่างผิดพลาด`,
  onClose = () => console.log('onClose')
}) {
  let btnText = "ปิด"

  // No Flow Popup
  const errCode = localStorage.getItem('errCode')
  if (errCode === "401") {
    title = 'Session Expired';
    subTitle = '';
    content = 'Please log in again';
    localStorage.removeItem('errCode')
    btnText = "Close"
    onClose = () => {
      helper.Auth.Logout(() => {
        window.location.href = '/login'
      })
    }
  }


  return (
    <DialogCustom visible={open} style={{ width: '342px' }} onHide={() => onClose()} onClickOut = {() => onClose()} draggable={false} resizable={false} closable={false}>
      <div className="bModal bCard" id="ModalAlert">
        <div className="bInner" style={{ width: "100%"}}>
          <div className="bDetails">
            {/* <!-- <i className="icoAlert"></i> --> */}
            { title ? <h3 dangerouslySetInnerHTML={{ __html: title }}></h3> : <></> }
            { subTitle ? <p>{ subTitle }</p> : <></> }
            { content ? <p>{ content }</p> : <></> }
          </div>
          <div className="bBtn" style={{ display: 'flex', flexDirection: "column", gap: "16px" }}>
            <a onClick={() => onClose()} className="btn">{btnText}</a>
          </div>
        </div>
      </div>
    </DialogCustom>
  )
}

export default ModalAlert;
