import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import useProfileStore from "services/store/ProfileModule";
import helper from "services/helper";

const PageHeader = ({ 
  title = "",
  notClose = false,
  onBack = () => console.log('onBack')
}) => {
  const navigate = useNavigate();
  
  // CENTER STATE
  const { profile } = useProfileStore(); // STATE
  

  useEffect(() => {}, []);

  return (
    <section id="pPageHeader">
    <div className="bHeader">
      {
        notClose == true
        ? <></>
        : <a className="btnIconBack <?php echo $btnBack; ?>" onClick={() => onBack()}></a>
      }
      <h1 className="bTitle">
        {title}
      </h1>
    </div>
  </section>
  );
};

export default PageHeader;
