import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import ModalContext from "contexts/ModalContext";
import PopupCollectPoint from "pages/collectPoint/PopupCollectPoint";
import QrScanNew from "components/QrScanNew";

const Example = () => {
  const navigate = useNavigate();
  const { mAlert } = useContext(ModalContext);
  const [ isOpen, setIsOpen ] = useState(false);

  const onAlert = () => {
    mAlert.info({
      title: "$title",
      subTitle: "$subTitle",
      content: "",
      onClose: () => { alert('onClose') },
    })
  }
  
  useEffect(() => {}, []);


  const onDetect = (value) => {
    console.log("🚀 ~ onDetect ~ value:", value)
    alert(value)
  }

  return (
    <div>
      <Helmet>
        <title></title>
        <meta name="title" content="" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>
      <div id="pageTimeSelect">
        <main>
          <h1>Example</h1>
          <h4>isOpen : {isOpen ? "true" : "false"}</h4>
          
          <div className="exModal">
            <button onClick={() => onAlert()}>Example Alert Info</button>
            <button onClick={() => setIsOpen(true)}>Alert Case</button>
            <PopupCollectPoint open={isOpen} onClose={() => setIsOpen(false)}/>
            {/* <QrCodeScanner onDetect={onDetect}/> */}
            <QrScanNew/>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Example;


