import ModalContext from "contexts/ModalContext";
import ModalProvider from "contexts/ModalProvider";
import { useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";
import helper from "services/helper";
import useProfileStore from "services/store/ProfileModule";

function Authen({ children }) {
  // const navigate = useNavigate()
  const { mAlert } = useContext(ModalContext);
  const profileState = useProfileStore((state) => state);

  // CENTER STATE
  const { profile, getCustomerProfile } = useProfileStore(); // STATE  

  console.log("profileState ALL >>", profileState);

  // เรียกข้อมูล Customer SNOWBALL API Part 2
  const fetchCustomerProfile = async () => {
    try {
      const resData = await getCustomerProfile()
      console.log("🚀 ~ fetchCustomerProfile ~ resData:", resData)
      
      if (resData.code === 20000) {
        // setIsHasProfile(true)
      }
      // setIsDone(true)
    }
    catch (err) {
      console.log('err ', err)
      // setIsDone(true)
      mAlert.info({
        title: "แจ้งเตือน",
        subTitle: "มีบางอย่างผิดพลาด",
        content: "",
        onClose: () => {},
      })
    }
  }

  const fnCheckLogin = () => {
    const isLogin = helper.Auth.isLogin()
    if (isLogin) {
      console.log('ดีดไป Profile')
      fetchCustomerProfile()
    } else {
      console.log('ดีดไป Login')
      // navigate("/login")
      // navigate("/login")
    }
  }

  useEffect(() => {
      fnCheckLogin()
  }, []);

  return (
    <>{children}</>
  );
}

export default Authen;
