import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import ModalContext from "contexts/ModalContext";
import helper from "services/helper";
import PageHeader from "components/PageHeader";
import { SearchCustomer } from "services/Api/Module/Customer";
import useProfileStore from "services/store/ProfileModule";
import { CreateCollectPoint } from "services/Api/Module/CollectPoint";
import BottomAction from "components/BottomAction";

const defPointCondition = { bathPerPoint: 25, point: 1 }
const CollectPointConfirm = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const contactNumber = searchParams.get("contactNumber");
  const custCode = searchParams.get("custCode");

  // POPUP
  const { mAlert } = useContext(ModalContext);

  // CENTER STATE
  const { profile, getCustomerProfile } = useProfileStore(); // STATE  

  // LOCAL STATE
  const [ isSubmit, setIsSubmit ] = useState(false)
  const [ customer, setCustomer ] = useState({})
  const [ reference, setReference ] = useState("")
  const [ cash, setCash ] = useState("")
  const [ pointCondition, setPointCondition ] = useState(defPointCondition)

  // SearchCustomer
  const fetchSearchCustomer = async (payload) => {
    try {
      const response = await SearchCustomer({ params: payload })
      const resData = response.data
      if (resData.resultCode === 20000) {
        setCustomer(resData.resultData)
      } else {
        mAlert.info({
          title: "ไม่พบเบอร์มือถือ",
          subTitle: "เบอร์มือถือนี้ไม่ตรงกับเบอร์ในระบบ",
          content: "",
          onClose: () => {},
        })
      }
    }
    catch (err) {
      console.log('err ', err)
      mAlert.info({
        title: "แจ้งเตือน",
        subTitle: "มีบางอย่างผิดพลาด",
        content: "",
        onClose: () => {},
      })
    }
  }

  const fetchCustomerProfile = async () => {
    try {
      const resData = await getCustomerProfile()
      console.log("🚀 ~ fetchCustomerProfile ~ resData:", resData)
      
      if (resData.code === 20000) {
        // setIsHasProfile(true)
      }
      // setIsDone(true)
    }
    catch (err) {
      console.log('err ', err)
      // setIsDone(true)
      mAlert.info({
        title: "แจ้งเตือน",
        subTitle: "มีบางอย่างผิดพลาด",
        content: "",
        onClose: () => {},
      })
    }
  }

  const postCollectPoint = async (payload) => {
    try {
      const response = await CreateCollectPoint({ data: payload })
      const resData = response.data
      console.log("🚀 ~ postCollectPoint ~ resData:", resData)
      if (resData.resultCode === 20000) {
        // setCustomer(resData.resultData)
        mAlert.info({
          title: "สะสมคะแนนสำเร็จ",
          subTitle: "",
          content: "",
          onClose: () => { navigate("/profile")},
        })
      } else {
        mAlert.info({
          title: "ไม่พบข้อมูล",
          subTitle: "ข้อมูลนี้ไม่ตรงกับข้อมูลในระบบ",
          content: "",
          onClose: () => {},
        })
      }
    }
    catch (err) {
      console.log('err ', err)
      mAlert.info({
        title: "แจ้งเตือน",
        subTitle: "มีบางอย่างผิดพลาด",
        content: "",
        onClose: () => {},
      })
    }
  }

  

  const onSave = () => {
    // alert("onSave!")
    setIsSubmit(true)

    const payload = {
      customerId: customer.id,
      cash: cash,
      reference: reference
    }
    console.log("🚀 ~ onSave ~ payload:", payload)

    if (cash > 0) {
      if (cash >= 10000) {
        mAlert.info({
          title: `ยอดใช้จ่ายของลูกค้าเกิน <span>10,000</span> บาท ตรวจสอบข้อมูลถูกต้อง`,
          subTitle: "",
          type: "confrim",
          content: "",
          onClose: () => {},
          onYes: () => {
            postCollectPoint(payload)
          },
          onNo: () => { console.log('SAY NO') },
        })
      } else {
        postCollectPoint(payload)
      }
    }
    
  }

  const onCancel = (e) => {
    // alert("onCancel")

    mAlert.info({
      title: "ยกเลิกการสะสมคะแนน",
      subTitle: "หากกดยกเลิก ข้อมูลที่คุณบันทึกไว้จะหายไป",
      type: "confrim",
      mode: "red",
      content: "",
      onClose: () => {},
      onYes: () => { navigate("/profile")},
      onNo: () => { console.log('SAY NO') },
    })
  }

  useEffect(() => {
    const payload = { contactNumber: contactNumber, custCode: custCode }
    fetchSearchCustomer(payload)
  }, [contactNumber, custCode]);
  
  useEffect(() => {
    if(profile.id) {
      const tmp = {
        bathPerPoint: profile.pointConditionBathPerPoint,
        point: profile.pointConditionPoint
      }
      setPointCondition(tmp)
    }
  }, [profile]);

  useEffect(() => {
    fetchCustomerProfile()
  }, []);
  
  
  console.log('profile >>', profile)
  console.log("🚀 ~ CollectPointConfirm ~ reference:", reference)
  console.log("🚀 ~ CollectPointConfirm ~ cash:", cash)

  // VALIDATE
  const ckInput = (condition = false) => (isSubmit && !condition) ? 'error' : ''

  const _pointCondition = pointCondition
  const calPoint = Math.floor(cash / _pointCondition.bathPerPoint) * _pointCondition.point

  return (
    <div>
      
      <Helmet>
        <title></title>
        <meta name="title" content="" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>


      <div id="pageRestaurant-point-details">

        {/* <!-- Page Header --> */}
        <PageHeader title="ยืนยันข้อมูลสะสมคะแนน" onBack={() => navigate("/profile")}/>

        {/* <!-- Content Page --> */}
        <main id="top">
          <div className="wrapContent">
            <section id="pRegister">
              <div className="bRegisterMain">
                <div className="bForm">
                  <div className="bGroup">
                    <h2>ข้อมูลสะสมคะแนน</h2>
                    <div className="bRowInfo">
                      <h4>พนักงาน</h4>
                      <p>{profile?.firstName ?? '-'}</p>
                    </div>
                    <div className="bRowInfo">
                      <h4>ร้าน</h4>
                      <p>{profile?.restaurantBranchName ?? '-'}</p>
                    </div>
                    <div className="bRowInfo">
                      <h4>สาขา</h4>
                      <p>{profile?.restaurantName ?? '-'}</p>
                    </div>
                    <div className="bRowInfo">
                      <h4>ลูกค้า</h4>
                      <p>{customer?.firstName ?? '-'} {customer?.lastName ?? ''}</p>
                    </div>
                    <div className="bRowInfo">
                      <h4>รหัสลูกค้า</h4>
                      <p>{customer?.custCode ?? '-'}</p>
                    </div>
                    <div className="bRowInfo">
                      <div>
                        <h3>หมายเลขอ้างอิง</h3>
                        <div className="control-group">
                          <input 
                            type="text" 
                            value={reference} 
                            onInput={helper.FNFORM.handleCharOnly}
                            onChange={(e) => setReference(e.target.value)} 
                            maxLength={25}
                            name="" 
                            placeholder="หมายเลขอ้างอิง"
                          />
                          <label for="" className="tError">Text Error Input</label>
                        </div>
                      </div>
                    </div>
                    <div className="bRowInfo">
                      <div>
                        <h3>ยอดรวมสุทธิ</h3>
                        <div className="control-group">
                          <input 
                            type="text" 
                            value={cash} 
                            onInput={helper.FNFORM.handleNumberDotOnly}
                            onChange={(e) => setCash(e.target.value)}
                            maxLength={25}
                            name="" 
                            placeholder="ยอดรวมสุทธิ"
                          />
                          <label for="" className="tError">Text Error Input</label>
                        </div>
                      </div>
                    </div>
                    <div className="bRowInfo">
                      <div>
                        <h3>คะแนนที่จะได้รับ</h3>
                        <div className="control-group">
                          <input 
                            type="text" 
                            value={helper.FN.toNumberWithCommas(calPoint) + " คะแนน"} 
                            name="text" 
                            disabled={true}
                          />
                          <label for="" className="tError">Text Error Input</label>
                        </div>
                        <p>รับ {_pointCondition.point} คะแนน ทุก ๆ การใช้จ่าย {_pointCondition.bathPerPoint} บาท</p>
                      </div>
                    </div>
                  </div>
                </div>                
              </div>
            </section>

            {/* <!-- Page Button Menu --> */}
            <BottomAction onYes={onSave} onNo={onCancel} />
          </div>
        </main>
      </div>
    </div>
  );
};
export default CollectPointConfirm;