import ModalProvider from "contexts/ModalProvider";
import Authen from "./Authen";

function Layouts({ children }) {

  return (
    <ModalProvider>
      <Authen>
        {children}
      </Authen>
    </ModalProvider>
  ) 
}

export default Layouts;
