import { get, post, put } from "../Config";

export function authLogin({ data }) {
  return post({ url: `/api/impact-admin/v1/login`, data });
}
export function authLogout({ data }) {
  return post({ url: `/api/impact-admin/v1/logout`, data });
}
export function GetProfile({ params = {} }) {
  return get({ url: `/api/impact-admin/v1/employee`, params });
}