import React from 'react';

const NotFound = () => {
  return (
    <div id="pageRestaurant-login">
      <main id="top">
        <div className="wrapContent">
          <section id="pRegister">
            <div className="bRegisterMain">
            <div style={{ paddingTop: '30vh'}}>
              <h1 style={{ textAlign: 'center' }}>404 - Not Found</h1>
              <p style={{ textAlign: 'center' }}>The page you are looking for does not exist.</p>
            </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default NotFound;